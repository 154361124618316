import {Footer} from "./Footer";
import * as React from "react";
import {Navbar} from "./Navbar";
import {Section} from "./Section";
import {Container} from "./Container";
import {Breadcrumbs} from "./Breadcrumbs";

export function Layout({children, pathname, title}) {

    return <>
        <Navbar/>
        <Section>
            <Container>
                <Breadcrumbs pathname={pathname}/>
                <h1 className={"title is-size-1 has-text-primary"}>{title}</h1>
                {children}
            </Container>
        </Section>
        <Footer/>
    </>
}