import * as React from "react"
import {useState} from "react";
import {Container} from "./Container";

export function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    return <nav className="navbar is-primary" role="navigation" aria-label="main navigation" >
        <Container>
            <div className="navbar-brand">
                {/*<a className="navbar-item" href="/">*/}
                    {/*<img src="https://bulma.io/images/bulma-logo.png" width="112" height="28"/>*/}
                {/*</a>*/}

                <a role="button" className={"navbar-burger burger" + (menuOpen ? " is-active" : "")} aria-label="menu" aria-expanded="false"
                   data-target="navbarBasicExample" onClick={() => {
                    setMenuOpen(!menuOpen);
                }}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbarBasicExample" className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
                <div className="navbar-start">
                    <a className="navbar-item" href={"/"}>
                        Home
                    </a>

                    <a className="navbar-item" href={"/blog"}>
                        Blog
                    </a>

                    {/*<div className="navbar-item has-dropdown is-hoverable">*/}
                    {/*<a className="navbar-link">*/}
                    {/*More*/}
                    {/*</a>*/}

                    {/*<div className="navbar-dropdown">*/}
                    {/*<a className="navbar-item">*/}
                    {/*About*/}
                    {/*</a>*/}
                    {/*<a className="navbar-item">*/}
                    {/*Jobs*/}
                    {/*</a>*/}
                    {/*<a className="navbar-item">*/}
                    {/*Contact*/}
                    {/*</a>*/}
                    {/*<hr className="navbar-divider"/>*/}
                    {/*<a className="navbar-item">*/}
                    {/*Report an issue*/}
                    {/*</a>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                </div>

                {/*<div className="navbar-end">*/}
                {/*<div className="navbar-item">*/}
                {/*<div className="buttons">*/}
                {/*<a className="button is-primary">*/}
                {/*<strong>Sign up</strong>*/}
                {/*</a>*/}
                {/*<a className="button is-light">*/}
                {/*Log in*/}
                {/*</a>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
            </div>
        </Container>
    </nav>
}