import * as React from "react";

export function Breadcrumbs(props: {pathname: string}) {

    const pathName = props.pathname;
    const pathSplit = pathName.split("/");

    if(pathSplit[pathSplit.length - 1] == ""){
        pathSplit.pop();
    }


    const breadCrumbs: Array<{
        label: string
        path: string
    }> = pathSplit.reduce((prev, cur) => {
        if(prev.length == 0){
            return [{
                "label": "home",
                "path": "/"
            }]
        }else{
            const last = prev[prev.length-1];
            return [
                ...prev,
                {
                    "label": cur,
                    "path": last.path + cur + "/"
                }
            ]
        }
    }, []);

    return <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
            {breadCrumbs.map((breadcrumb, i) => {
                const isActive = i == breadCrumbs.length - 1;
                if(isActive){
                    return <li key={breadcrumb.path} className="is-active"><a href="#" aria-current="page">{breadcrumb.label}</a></li>
                }else{
                    return <li key={breadcrumb.path}><a href={breadcrumb.path}>{breadcrumb.label}</a></li>
                }

            })}
        </ul>
    </nav>
}