import * as React from "react";
import {Instagram} from "./socials/Instagram";
import {Facebook} from "./socials/Facebook";
import {Subscribe} from "./Subscribe";
import {IconContext} from "react-icons";
import {graphql} from "gatsby";
import Img from "gatsby-image";
export function BlogSidebar({profileImage}) {
    console.log(profileImage)
    return <div className="column is-narrow is-hidden-touch" >
        <div style={{
            position: "sticky",
            top: "1em",
            width: 300,
        }}>
            <div className={"box"}>
                <p className="title is-5">Dragon Drummer</p>
                <p className="subtitle">Devblog</p>




                <p style={{
                    display: "flex",
                    alignItems: 'center'
                }}>
                    <Subscribe/>
                    <IconContext.Provider value={{size: "2em"}}>
                        <Instagram isLarge={true}/>
                        <Facebook isLarge={true}/>
                    </IconContext.Provider>
                </p>

                <div style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <a href={"https://www.instagram.com/dragondrummergame/"} rel="noopener" target="_blank">
                        <Img fixed={profileImage.childImageSharp.fixed} />
                    </a>
                </div>
                <hr/>
                <aside className="menu">
                    <p className="menu-label">
                        Categories
                    </p>
                    <ul className="menu-list">
                        <li><a href={"/blog/#weekly-update"}>Weekly Update 🗓</a></li>
                        <li><a href={"/blog/#dev"}>Dev 💻</a></li>
                        <li><a href={"/blog/#music"}>Music 🎵</a></li>
                        <li><a href={"/blog/#art"}>Art 🎨</a></li>
                    </ul>
                </aside>
            </div>
        </div>
    </div>
}