import * as React from "react";
import {IconContext} from "react-icons";
import {Instagram} from "./socials/Instagram";
import {Facebook} from "./socials/Facebook";
import {Subscribe} from "./Subscribe";

export function Footer() {
    return <footer className="footer">

        <IconContext.Provider value={{size: "2em"}}>
        <div className="container has-text-centered">
            <strong>Dragon Drummer</strong> by oatmealstudios
            <p>
                <Instagram/>
                <Facebook/>
            </p>
        </div>
        </IconContext.Provider>

    </footer>
}