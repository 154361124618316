import {graphql} from "gatsby";
import * as React from "react";

import "../main.scss"
import {Layout} from "../components/Layout";
import SEO from "../components/seo";
import {BlogSidebar} from "../components/BlogSidebar";
import {Helmet} from "react-helmet"
import {Commento} from "../components/Commento";

export default function blogpost(props) {

    const post = props.data.markdownRemark;
    // console.log(props);
    // const post = props.data.mdx
    const siteTitle = props.data.site.siteMetadata.title
    const { previous, next } = props.pageContext

    const pathname = props.location.pathname;
    return <>
        <SEO
            title={post.frontmatter.title}
            description={post.frontmatter.description}
        />
        <Layout pathname={pathname} title={post.frontmatter.title}>
            <div className="columns">
                <div className="column">
                    <div className="content is-medium" dangerouslySetInnerHTML={{ __html: post.html }}>
                    </div>
                    <hr/>
                    <p className="subtitle">{post.frontmatter.date}, by Larry Wang</p>
                </div>
                <BlogSidebar profileImage={props.data.pigprofile}/>
            </div>
            <Commento id={pathname}/>
        </Layout>
    </>

}
//
export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    pigprofile: file(relativePath: { eq: "pig_profile.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`