import * as React from "react";

let href = "https://5f47c075.sibforms.com/serve/MUIEAGIaFcxl5B3FH0OxeoGGelK_nflMJfw8dmBEUG5hoWmVeotUn8whBqlPz-Vw8Zb-VPITd84m-PB5nvLbFp_CT0C_yalzV2H32JO-BaYYG3zehay5NI4QqMKoEakSu_N3T7WzDlzUCZbsog_EyZjnJDHaiuXT7rQgJhB35EeJUChvgNv2TpNVPMZORmMMOrvSP0gG6uoCNBIK";

export function Subscribe() {
    return (

        <a href={href} className="button is-size-6 is-link is-outlined">
            Subscribe
        </a>
    )
}